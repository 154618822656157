import React, {Fragment} from 'react'
import { Link } from 'react-router-dom';
import { SubscribeFormOne } from '../form/SubscribeFormOne';
import CopyRightThree from './CopyRightThree';

const pageContent =[
    {
        name: 'Latest Blogs',
        routerPath: '/blogs'
    },
    {
        name: 'Website Security',
        routerPath: '/service-details'
    },
    {
        name: 'Website Development',
        routerPath: '/service-details'
    },
    {
        name: 'Much More',
        routerPath: '/service'
    },
]

const serviceContent =[
    {
        name: 'support@pentotest.com',
        routerPath: 'mailto:support@pentotest.com'
    },
    {
        name: '+91-9990934025',
        routerPath: '#'
    },
    {
        name: 'Basara, Basara Barabanki India 225305.',
        routerPath: '#'
    },

];

const socialContent =[
    {
        icon: 'fab fa-facebook-f',
        routerPath: 'https://www.facebook.com/shah96rukh/'
    },
    {
        icon: 'fab fa-twitter',
        routerPath: 'https://twitter.com/shah96rukh/'
    },
    {
        icon: 'fab fa-linkedin-in',
        routerPath: 'https://www.linkedin.com/in/shah96rukh/'
    },
    {
        icon: 'fab fa-instagram',
        routerPath: 'https://www.instagram.com/shah96rukh/'
    },
    {
        icon: 'fab fa-youtube',
        routerPath: 'https://www.youtube.com/channel/UCvZqkoAyxS00dKPRhfAqPlA'
    },

]



const Footer = () => {
    return (
        <Fragment>
            {/*footer-area start*/}
            <footer
                className="gradient-bg footer-area theme-footer-two pt-95 pb-0 pb-lg-10">
                <img className="footer-shape shape-1b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-3b.svg"} alt="shape"/>
                <img
                    className="footer-shape shape-2b d-none d-lg-inline-block"
                    src={process.env.PUBLIC_URL +  "/assets/img/shape/star-4b.svg"}
                    alt="shape"/>
                <img className="footer-shape shape-3b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-6b.svg"} alt="shape"/>
                <img
                    className="footer-shape shape-4b d-none d-lg-inline-block"
                    src={process.env.PUBLIC_URL +  "/assets/img/shape/star-5b.svg"}
                    alt="shape"/>
                <img className="footer-shape shape-5b" src={process.env.PUBLIC_URL +  "/assets/img/shape/star-5b.svg"} alt="shape"/>
                <img
                    className="fot-shape-one"
                    src={process.env.PUBLIC_URL +  "/assets/img/shape/line-round-7b.svg"}
                    alt="footer shape"/>
                <div className="container">
                    <div className="row gx-4 gx-xxl-5 mb-10">
                        <div className="col-xl-4 col-md-4" data-aos="fade-up">
                            <div className="footer__widget mb-40">
                                <h4 className="widget-title mb-35">Quick Links</h4>
                                <ul className="fot-list">
                                   {pageContent.map((val, i)=>(
                                        <li key={i}>
                                            <Link to={val.routerPath}>{val.name}</Link>
                                        </li>
                                   ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4" data-aos="fade-up" data-aos-delay={100}>
                            <div className="footer__widget mb-40 ps-xxl-4">
                                <h4 className="widget-title mb-35">Contact</h4>
                                <ul className="fot-list">
                                    {serviceContent.map((val, i)=>(
                                        <li key={i}>
                                            <a href={val.routerPath}>{val.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div
                            className="col-xl-4 col-md-4 ps-xxl-3"
                            data-aos="fade-up"
                            data-aos-delay={200}>
                            <div className="footer__widget mb-40 px-xxl-5">
                                <h4 className="widget-title mb-35">Subscribe</h4>
                                <p className="fs-17 mb-0 pb-20">Subscribe Our Newsletter
                                </p>
                                {/* SubscribeFormOne */}
                               <SubscribeFormOne />
                                {/* SubscribeFormOne */}
                                <div className="footer__social mb-30">
                                    {socialContent.map((val, i)=>(
                                        <a key={i} href={val.routerPath} target="_blank" rel="noopener noreferrer">
                                            <i className={val.icon}/>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <CopyRightThree />
                </div>
            </footer>
            {/*footer-area end*/}

        </Fragment>
    )
}

export default Footer