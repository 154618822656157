import React, { Fragment, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import axios from 'axios'
import ProgressBar from 'react-bootstrap/ProgressBar'
import AWN from 'awesome-notifications'

//componets
import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import ModalVideos from '../../../components/modal-video/ModalVideos'
import Newsletter from '../../../components/call-to-action/Newsletter'
import Footer from '../../../components/footer/Footer'
import Spinner from '../../../components/Spinner'
import WidgetFour from '../../../components/widget/WidgetFour'

const socialContent = [
    {
        icon: 'fab fa-facebook-f',
        routerPath: 'https://www.facebook.com/sharer/sharer.php?u=https://www.pentotest.com/product-details'
    },
    {
        icon: 'fab fa-twitter',
        routerPath: 'https://twitter.com/intent/tweet?url=https://www.pentotest.com/product-details&text='
    },
    {
        icon: 'fab fa-linkedin-in',
        routerPath: 'https://www.linkedin.com/shareArticle?mini=true&url=https://www.pentotest.com/product-details'
    },

]

const Content = [
    {
        course_img: '/assets/img/course/imsi-course.gif',
        course_type: 'IMSI Catcher',
        course_tittle: 'Cellular Network Hacking Course',
        course_slug: 'cellular-network-hacking-course',
    }

];

const Product = () => {
    const cookies = new Cookies();
    const [open, setOpen] = useState(false);
    const OpenModal = () => {
        setOpen(!open)
    }
    const [pageCount, setCount] = useState(1);
    const [datas, setUsers] = useState([]);
    const [totalPage, setTotalPage] = useState([]);
    const [dataAverage, setAverage] = useState([]);
    const [Lodershow, setLoginShow] = useState(false)
    const handleLoginClose = () => setLoginShow(false)
    const handleLoginShow = () => setLoginShow(true)

    const Buynow = () => {
        const data = { product_id: "6331a3a4a7d4cd4ee1d504a5", product_name: "Cellular Network Hacking Course", product_price: 20 }
        cookies.set('Cart', data);
        window.location.href = '/cart';
    };

    const Addtocart = () => {
        const data = { product_id: "6331a3a4a7d4cd4ee1d504a5", product_name: "Cellular Network Hacking Course", product_price: 20 }
        cookies.set('Cart', data);
        window.location.reload();
        new AWN().success("Product added.");
    };

    const fetchData = () => {
        handleLoginShow()
        setCount(pageCount + 1);
        axios.post(`https://api.pentotest.com/fetchreviewforcourse/${pageCount}`)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    handleLoginClose()
                    setUsers(res.data.data);
                }
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        handleLoginShow()
        axios.post(`https://api.pentotest.com/fetchreviewforcourse/${pageCount}`)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    handleLoginClose()
                    setUsers(res.data.data);
                    setTotalPage(res.data.totalPage);
                }
            }).catch(error => {
                console.log(error);
            });

        axios.post(`https://api.pentotest.com/fetchreviewav`)
            .then(res => {
                if (res.data.status === 1) {
                    setAverage(res.data.data);
                } else {
                    new AWN().alert("Server error.");
                }
            }).catch(error => {
                console.log(error);
            });

    }, [pageCount]);

    return (
        <Fragment>
            <ModalVideos isOpen={open} onClick={OpenModal} />
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Cellular Network Hacking Course | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuThree */}
                <Header />
                {/* ThemeMenuThree */}

                {/* PageTitle */}
                <PageTitle title="Cellular Network Hacking Course" />
                {/* PageTitle */}

                {/*blog-single-area start*/}
                <section className="blog-single-area pt-150 pb-80 pt-lg-60">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-8 mb-60">
                                {Content.map((data, i) => (
                                    <div key={i} className="blog-style-five mb-25 text-white">
                                        <div className="blog-content">
                                            <div className="blog-tag pb-15">
                                                {data.course_type}
                                            </div>
                                            <h3 className="text-white"><Link className="blog-title" to="/blog-details-v1">{data.course_tittle}</Link></h3>
                                            <div className="blog-meta mb-35">
                                                <a href={() => false} className="mr-20"><i className="bi bi-person-plus" /> Shahrukh Khan</a>
                                                <a href={() => false}><i className="bi bi-clock" /> 21 Jan 2024</a>
                                            </div>
                                        </div>

                                        <div className="blog-thumb mb-40">
                                            <img className="w-100" src={process.env.PUBLIC_URL + data.course_img} alt={data.course_tittle} />
                                        </div>
                                        <div className="ml-30">
                                            <br />
                                            <h4 className="text-white">What you'll learn</h4>
                                            <br />
                                            <i className="fa fa-check mr-20 text-white" aria-hidden="true"></i>Make sure to hack IMSI Catcher.
                                            <br />
                                            <i className="fa fa-check mr-20 text-white" aria-hidden="true"></i>Understating how to works IMSI Catcher.
                                            <br />
                                            <i className="fa fa-check mr-20 text-white" aria-hidden="true"></i>Under make IMSI Catcher.
                                            <br />
                                            <br />
                                            <h4 className="text-white">Course content</h4>
                                            <br />
                                            <b className="text-white">10 sections • 10 lectures</b>
                                            <div className="mt-20 mb-20">
                                                <div className="hr"></div>
                                            </div>
                                            <h4 className="text-white">About this course</h4>
                                            <br />
                                            <p className="text-white">IMSI Catcher, SS7, RTL-SDR, HackRF One, USRP, BladeRF, SRSLTE, OpenLTE, OsmocomBB etc.</p>
                                            <br />
                                            <h4 className="text-white">By the numbers</h4>
                                            <br />
                                            <li className="text-white">Skill level: Beginner Level</li>
                                            <li className="text-white">Languages: English</li>
                                            <li className="text-white">Captions: Yes</li>
                                            <li className="text-white">Lectures: 10</li>
                                            <div className="mt-20 mb-20">
                                                <div className="hr"></div>
                                            </div>
                                            <h4 className="text-white">Features</h4>
                                            <br />
                                            <li className="text-white">Windows 10 & 11</li>
                                            <li className="text-white">Mac any</li>
                                            <li className="text-white">IPhone or IOS</li>
                                            <li className="text-white">Android</li>
                                            <div className="mt-20 mb-20">
                                                <div className="hr"></div>
                                            </div>
                                            <h4 className="mb-10 text-white">Description</h4>
                                            <br />
                                            <p className="text-white">An IMSI catcher or international mobile subscriber identity-catcher is a telephone eavesdropping device used for intercepting cellular mobile phone traffic and tracking location data of mobile phone users.</p>
                                            <p className="text-white">IMSI Catcher operates on a fake mobile phone tower between the target mobile phone and the real towers of the service provider, it is considered a man-in-the-middle (MITM) attack.</p>
                                            <p className="text-white">The 3G or 4g wireless cellular network mutual authentication required from both the handset and the network. However, but may be able to downgrade 3G and LTE to non-LTE network services which do not require mutual authentication.</p>
                                            <br />
                                            <h4 className="mb-20 text-white">Required Hardware and Software</h4>
                                            <br />
                                            <h5 className="mb-10 text-white">Hardware: </h5>
                                            <br />
                                            <li className="text-white">USB DVB-T key (RTL2832U) with antenna (less than 15$)</li>
                                            <li className="text-white">HackRF one</li>
                                            <li className="text-white">USRP or BladeRF</li>
                                            <br />
                                            <h5 className="mb-10 text-white">Software & OS: </h5>
                                            <br />
                                            <li className="text-white">GR-GSM Tool for 2G</li>
                                            <li className="text-white">OsmocomBB</li>
                                            <li className="text-white">OpenLTE</li>
                                            <li className="text-white">SRSLTE</li>
                                            <li className="text-white">DragonOS</li>
                                            <li className="text-white">SigintOS</li>
                                            <div className="mt-20 mb-20">
                                                <div className="hr"></div>
                                            </div>
                                            <h6 className="mb-10 text-white">What you’ll learn: </h6>
                                            <br />
                                            <li className="text-white">How to works IMSI Catcher or SS7.</li>
                                            <li className="text-white">How to works Signals system.</li>
                                            <li className="text-white">What is OsmocomBB, SRSLTE, OpenLTE.</li>
                                            <li className="text-white">What is encryption mode.</li>
                                            <br />
                                            <h6 className="mb-1 text-white">Who this course is for: </h6>
                                            <br />
                                            <li className="text-white">Students who want to learn cellular network sniffing.</li>
                                            <li className="text-white">Understand signal systems.</li>
                                        </div>
                                        <br />
                                        <h3 className="ml-30 text-white">Instructors</h3>
                                        <br />
                                        <div className="ml-30">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                                    <img className="avatar" src={process.env.PUBLIC_URL + "/assets/img/about/me.png"} alt="Shahrukh Khan" />
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                                    <div className="ml-0">
                                                        <h5 className="text-capitalize text-white">
                                                            Shahrukh Khan
                                                        </h5>
                                                        <div><i className="fa fa-star checked mr-10" aria-hidden="true"></i>4.3 Instructor Rating
                                                        </div>
                                                        <div><i className="fa fa-comments-o text-success mr-10" aria-hidden="true"></i>1,259 Reviews
                                                        </div>
                                                        <div><i className="fa fa-play-circle text-danger mr-10" aria-hidden="true"></i>1 Courses
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="mt-20 mb-30 text-white">
                                                        <p className="text-white">Hello and welcome to my website. My name is Shahrukh Khan, and I am a highly skilled cybersecurity professional with over five years of experience in the field. I am based in India and have a passion for helping individuals and organizations improve their cybersecurity posture to protect their valuable assets.</p>
                                                        <p className="text-white">My expertise lies in network security, data protection, threat analysis, and incident response. In addition to my experience, I hold the Certified Ethical Hacker (CEH) practical certification, which is a testament to my skills and knowledge in the field.</p>
                                                        <p className="text-white">Throughout my career, I have demonstrated a commitment to staying up-to-date with the latest cybersecurity trends and technologies, and I pride myself on being a lifelong learner in this ever-evolving field. My goal is to help my clients identify and mitigate cyber threats and improve their overall security posture.</p>
                                                        <p className="text-white">Thank you for visiting my website, and please don't hesitate to contact me if you have any questions or if you would like to discuss how I can assist you with your cybersecurity needs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                <div>
                                    <div className="mt-20 mb-30">
                                        <div className="hr"></div>
                                    </div>
                                    <h3 className="ml-30 text-white">Feedback</h3>
                                    {dataAverage.map(data => (
                                        <div key={data.id} className="row">
                                            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                                <div className="text-center">
                                                    <h1 className="display-3 text-white"><b>{data.avtwodi}</b></h1>
                                                    <p className="text-white">out of 5</p>
                                                    {data.avtwodi < 1 ? <>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                    </> : 1 <= data.avtwodi && data.avtwodi <= 1.4 ? <>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                    </> : 1.5 <= data.avtwodi && data.avtwodi < 2 ? <>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star-half checked mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                    </> : 2 <= data.avtwodi && data.avtwodi <= 2.4 ? <>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                    </> : 2.5 <= data.avtwodi && data.avtwodi < 3 ? <>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star-half checked mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                    </> : 3 <= data.avtwodi && data.avtwodi <= 3.4 ? <>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                    </> : 3.5 <= data.avtwodi && data.avtwodi < 4 ? <>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star-half checked mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                    </> : 4 <= data.avtwodi && data.avtwodi <= 4.4 ? <>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star mx-1"></span>
                                                    </> : 4.5 <= data.avtwodi && data.avtwodi < 5 ? <>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star-half checked mx-1"></span>
                                                    </> : <>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                        <span className="fa fa-star checked mx-1"></span>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 mt-3">

                                                <div className="row text-left mb-1">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                                                        <div className="rating-label text-white">Excellent</div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-6 mt-2">
                                                        <ProgressBar striped variant="warning" now={data.avperfive} />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                                        <div>{data.five}({data.perfive}%)</div>
                                                    </div>
                                                </div>

                                                <div className="row text-left mb-1">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                                                        <div className="rating-label text-white">Superb</div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-6 mt-2">
                                                        <ProgressBar striped variant="warning" now={data.avperfoure} />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                                        <div>{data.foure}({data.perfoure}%)</div>
                                                    </div>
                                                </div>

                                                <div className="row text-left mb-1">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                                                        <div className="rating-label text-white">Good</div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-6 mt-2">
                                                        <ProgressBar striped variant="warning" now={data.avperthree} />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                                        <div>{data.three}({data.perthree}%)</div>
                                                    </div>
                                                </div>

                                                <div className="row text-left mb-1">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                                                        <div className="rating-label text-white">Average</div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-6 mt-2">
                                                        <ProgressBar striped variant="warning" now={data.avpertwo} />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                                        <div>{data.two}({data.pertwo}%)</div>
                                                    </div>
                                                </div>

                                                <div className="row text-left mb-1">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                                                        <div className="rating-label text-white">Poor</div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-6 mt-2">
                                                        <ProgressBar striped variant="warning" now={data.avperone} />
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                                        <div>{data.one}({data.perone}%)</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}

                                    <div className="comments-wrapper  mt-55">
                                        <h3 className="post-title mb-50">Comments</h3>
                                        <ul className="comment-list list-none">
                                            {datas.map((data, i) => (
                                                <li key={i} className="">
                                                    <div className="comment-box">
                                                        <div className="comment-avatar">
                                                            <img className="avatar" src={data.image === 'default_image.png' ? process.env.PUBLIC_URL + '/assets/img/default_image.png' : data.image} alt={data.name} />
                                                            <br />
                                                            {data.rating === 1 ? <>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star"></span>
                                                                <span className="fa fa-star"></span>
                                                                <span className="fa fa-star"></span>
                                                                <span className="fa fa-star"></span>
                                                            </> : data.rating === 2 ? <>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star"></span>
                                                                <span className="fa fa-star"></span>
                                                                <span className="fa fa-star"></span>
                                                            </> : data.rating === 3 ? <>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star"></span>
                                                                <span className="fa fa-star"></span>
                                                            </> : data.rating === 4 ? <>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star"></span>
                                                            </> : <>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star checked"></span>
                                                                <span className="fa fa-star checked"></span>
                                                            </>}
                                                        </div>
                                                        <div className="comment-content">
                                                            <h5 className="text-white text-capitalize">{data.name}
                                                            </h5>
                                                            <p className="fs-14 mb-15"><i className="far fa-calendar-alt" />
                                                                {data.created_at}</p>
                                                            <p className="text-white">{data.feedback}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className={Lodershow ? `divOne` : 'divOne d-none'}>
                                        <div className="text-center">
                                            <Spinner />
                                        </div>
                                    </div>
                                    {datas.length > 0 ?
                                        totalPage === datas.length ?
                                            <div></div> :
                                            <div className="text-center">
                                                <Link onClick={event => fetchData()} className="fa-2x"><i className="fa fa-chevron-circle-down text-success" aria-hidden="true"></i></Link>
                                            </div>
                                        :
                                        <div></div>
                                    }

                                </div>
                            </div>
                            <div className="col-lg-4 order-lg-first">
                                <div className="right-widget-section">
                                    <div className="widget widget-categories-list">
                                        <iframe className="embed-responsive" src="https://www.youtube.com/embed/NGrXp9V4KZw" title="Cellular Network Hacking Course" />
                                    </div>
                                    <div className="widget widget-categories-list mb-40">
                                        <div className="widget-title-box mb-30">
                                            <h4 className="widget-sub-title2 fs-20 text-white">Categories</h4>
                                        </div>
                                        <div className="mt-20">
                                            <div><h3 className="text-white">$20</h3><del className="mr-2">$200</del> (90.0% off)</div>
                                            <div className="text-danger"><i className="fa fa-clock-o" aria-hidden="true"></i><strong>10 day</strong>left at this price!</div>
                                        </div>
                                        <div className="mt-20">
                                            <button type="button" className="btn btn-primary w-100 btn-block mb-20" onClick={event => Addtocart()}>Add to card</button>
                                            <Link onClick={event => Buynow()} type="button" className="btn btn-outline-success w-100 btn-block mb-20">Buy now</Link>
                                        </div>
                                        <p className="text-center text-white"><small>0-Day Money-Back Guarantee</small></p>
                                        <p className="text-white"><b>This course includes:</b></p>
                                        <p className="text-white"><i className="fa fa-youtube-play mr-2" aria-hidden="true"></i>10 on-demand video</p>
                                        <p className="text-white"><i className="fa fa-file-text-o mr-2" aria-hidden="true"></i>1 aritcle</p>
                                        <p className="text-white"><i className="fa fa-download mr-2" aria-hidden="true"></i>3 downloadable resouces</p>
                                        <p className="text-white"><i className="fa fa-eye mr-2" aria-hidden="true"></i>3 months access</p>
                                        <p className="text-white"><i className="fa fa-laptop mr-2" aria-hidden="true"></i>Access on mobile and pc</p>
                                        <div className="mt-30 mb-30">
                                            <div className="hr"></div>
                                        </div>
                                        <div className="techno-blog-meta">
                                            <div className="techno-blog-meta-left">
                                                <b className="text-white">Share: </b>
                                                <div className="footer__social mb-30">
                                                    {socialContent.map((val, i) => (
                                                        <a key={i} href={val.routerPath} target="_blank" rel="noopener noreferrer">
                                                            <i className={val.icon} />
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget widget-categories-tag mb-60">
                                        <div className="widget-title-box mb-25">
                                            <h4 className="widget-sub-title2 fs-20">Tags</h4>
                                        </div>
                                        <div className="tag-list">
                                            <Link>Cellular hacking course</Link>
                                            <Link>imsi catcher course</Link>
                                            <Link>ss7 course</Link>
                                            <Link>Best ss7 hacking course</Link>
                                            <Link>Sniffing SMS</Link>
                                            <Link>Sniffing Call</Link>
                                            <Link>Sniffing Location</Link>
                                        </div>
                                    </div>
                                    <div className="widget widget-post mb-60">
                                        <div className="widget-title-box mb-30">
                                            <h4 className="widget-sub-title2 fs-20">Recent Post</h4>
                                        </div>
                                        {/* WidgetPost start */}
                                        <WidgetFour />
                                        {/* WidgetPost end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*blog-single-area end*/}

                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default Product